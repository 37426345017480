<template>
  <v-row>
    <v-dialog :value="dialog" v-on:input="updateValue($event)" max-width="500" persistent>
      <v-card>
        <v-card-title class="headline">
          New Airline
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="$emit('update:dialog',false)">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                label="Airline Name"
                prepend-icon="airplanemode_active"
                v-model="airline.airlineName"
                hide-details
              ></v-text-field>

              <v-text-field
                label="Commission"
                prepend-icon="show_chart"
                v-model="airline.commission"
                hide-details
              ></v-text-field>
              <v-checkbox label="Refund" v-model="airline.allowedRefund" dense hide-details></v-checkbox>
              <v-checkbox label="Select All Sectors" v-model="airline.selectAll" dense></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveNewAirline">
            <v-icon>save</v-icon>Save New Airline
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    dialog: {
      type: Boolean
    },
    airline: {
      airlineId: Number,
      airlineName: String,
      allowedRefund: Boolean,
      commission: Number,
      selectAll: Boolean,
      status: {
        type: String,
        require: true
      }
    },
    saveAirline: Function
  },
  methods: {
    updateValue: function(value) {
      this.$emit("update:dialog", value);
    },
    saveNewAirline: function() {
      this.saveAirline();
      //this.$emit("update:dialog", false);
    }
  },
  components: {}
};
</script>
