<template>
  <v-row>
    <v-col cols="12" md="3">
      <v-list shaped>
        <v-subheader>
          AIRLINE LIST
          <v-spacer />
          <v-btn color="green" dark text @click="addNewAirline">
            Add New
            <v-icon>add</v-icon>
          </v-btn>
        </v-subheader>
        <v-list-item-group v-model="selected" color="primary" class="listScroll">
          <v-list-item
            v-for="item in listAirline"
            :key="item.airlineId"
            @click="itemClick(item)"
            dense
          >
            <v-list-item-icon>
              <v-icon color="pink">airplanemode_active</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.airlineName"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar>
              <span v-text="item.commission"></span>
            </v-list-item-avatar>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-col cols="12" md="9">
      <v-row no-gutters>
        <v-col>
          <v-card flat v-if="airline.airlineId != 0">
            <v-card-title>
              Airline Details Information
              <v-spacer />
              <v-btn icon color="red" @click="deleteAirline">
                <v-icon>delete</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" md="4" class="pr-3" style="border-right: 1px solid grey">
                  <v-text-field
                    label="Airline Name"
                    prepend-icon="airplanemode_active"
                    v-model="airline.airlineName"
                    hide-details
                  ></v-text-field>

                  <v-text-field
                    label="Commission"
                    prepend-icon="show_chart"
                    v-model="airline.commission"
                    hide-details
                  ></v-text-field>
                  <v-checkbox label="Refund" v-model="airline.allowedRefund" dense hide-details></v-checkbox>
                  <v-checkbox label="Select All Sectors" v-model="airline.selectAll" dense></v-checkbox>
                </v-col>
                <v-col cols="12" md="7" offset-md="1" class="pl-3 sectorScroll">
                  <div class="subtitle-2">Select Sectors</div>
                  <v-row no-gutters>
                    <v-col cols="4" v-for="sector in sectorCheckList" :key="sector.sectorId">
                      <v-checkbox
                        :label="`${sector.sectorName} (${sector.shortName})`"
                        v-model="sector.checked"
                        hide-details
                        dense
                        @change="changeSector(sector)"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-divider class="my-2" />
                  <div class="subtitle-2">Flights</div>
                  <v-row>
                    <v-col cols="10" md="3">
                      <v-text-field
                        label="Flight Name"
                        v-model="flight.flightName"
                        hide-details
                        dense
                      />
                    </v-col>
                    <v-col cols="2" md="2">
                      <v-btn text icon color="green" class="mt-1" @click="addFlight">
                        <v-icon>add</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <template v-for="flight in listFlight">
                      <v-chip
                        v-if="flight.show"
                        class="ma-2"
                        color="primary"
                        close
                        :key="flight.flightId"
                        @click:close="closeFlight(flight)"
                      >{{ flight.flightName }}</v-chip>
                    </template>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <!-- <v-btn color="primary" block class="my-2">
                    Save
                    <v-icon>save</v-icon>
                  </v-btn>-->
                  <v-btn color="primary" block class="my-2" @click="updateAirline">
                    Update
                    <v-icon>update</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-row>
      <v-col cols="12" md="9">
        <airline-dialog
          :dialog.sync="airlineDialog"
          :airline="airlineDialogData"
          :saveAirline="saveAirline"
        />
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import flightService from "../service/FlightService";
import airlineService from "../service/AirlineService";
import sectorService from "../service/SectorService";
import AirlineDialog from "../components/AirlineDialog";

export default {
  mounted: function() {
    this.loadAllAirline();
    this.loadAllSector();
  },
  data() {
    return {
      selected: -1,
      airline: {
        airlineId: 0,
        airlineName: "",
        allowedRefund: false,
        commission: 0,
        selectAll: false,
        status: "ACTIVE"
      },
      listAirline: [],
      listSector: [],
      selectedSector: [],
      listFlight: [],
      flight: {
        flightId: 0,
        flightName: "",
        airline: {
          airlineId: 0
        },
        status: "ACTIVE"
      },
      airlineDialog: false,
      airlineDialogData: {
        airlineId: 0,
        airlineName: "",
        allowedRefund: false,
        commission: 0,
        selectAll: false,
        status: "ACTIVE"
      }
    };
  },
  methods: {
    itemClick: function(item) {
      this.airline = item;
    },
    closeFlight: function(flight) {
      flight.status = "DEACTIVE";
      flightService.deleteFlight(flight.flightId).then(data => {
        flight.show = false;
      });
    },
    addFlight: function() {
      flightService.addFlight(this.flight).then(data => {
        this.flight.flightName = "";
        data.show = true;
        this.listFlight.push(data);
      });
    },
    loadAirline: function(airlineId) {
      airlineService.getAirlineById(airlineId).then(data => {
        this.airline = data;
      });
    },
    loadFlightByAirlineId: function(airlineId) {
      flightService.getFlightByAirlineId(airlineId).then(data => {
        this.listFlight = this.listFlight.filter(f => false);
        data.forEach(e => {
          e.show = e.status == "ACTIVE" ? true : false;
        });
        this.listFlight.push(...data);
      });
    },
    updateAirline: function() {
      airlineService
        .updateAirline(this.airline.airlineId, this.airline)
        .then(data => {
          this.$swal2.fire({
            icon: "success",
            title: "Update airline success",
            showConfirmButton: false,
            timer: 1500
          });
          this.airline = data;
          this.loadAllAirline();
        });
    },
    loadAllAirline: function() {
      airlineService.getAllAirline().then(data => {
        this.listAirline = this.listAirline.filter(f => false);
        this.listAirline.push(...data);
      });
    },
    loadAllSector: function() {
      sectorService.getAllSector().then(data => {
        this.listSector = this.listSector.filter(f => false);
        this.listSector.push(...data);
      });
    },
    loadSectorByAirlineId: function(airlineId) {
      sectorService.getSectorByAirlineId(airlineId).then(data => {
        this.selectedSector = this.selectedSector.filter(f => false);
        this.selectedSector.push(...data);
      });
    },
    addNewAirline: function() {
      //airlineService.addAirline();
      this.airlineDialogData = {
        airlineId: 0,
        airlineName: "",
        allowedRefund: false,
        commission: 0,
        selectAll: false,
        status: "ACTIVE"
      };
      this.airlineDialog = true;
    },
    saveAirline: function() {
      airlineService.addAirline(this.airlineDialogData).then(data => {
        this.airlineDialog = false;
        this.loadAllAirline();
        this.airline.airlineId = data.airlineId;
      });
      //this.airlineDialogData
    },
    deleteAirline: function() {
      // this.$swal.fire({
      //   icon: "success",
      //   title: "Delete Success",
      //   showConfirmButton: false,
      //   timer: 1500
      // });
      this.$swal2
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        })
        .then(result => {
          if (result.value) {
            airlineService.deleteAirline(this.airline.airlineId).then(data => {
              this.airline.airlineId = 0;
              this.loadAllAirline();
              this.$swal2.fire(
                "Deleted!",
                "Your airline data has been deleted.",
                "success"
              );
            });
          }
        });
    },
    changeSector: function(sector) {
      if (sector.checked) {
        sectorService
          .saveOrDeleteSectorAndAirline(
            this.airline.airlineId,
            sector.sectorId,
            0
          )
          .then(data => {});
      } else {
        const findSelected = item => {
          return item.sector.sectorId == sector.sectorId;
        };
        let selectObj = this.selectedSector.find(findSelected);

        sectorService
          .saveOrDeleteSectorAndAirline(
            selectObj.airline.airlineId,
            selectObj.sector.sectorId,
            selectObj.airlineSectorId
          )
          .then(data => {});
      }
    }
  },
  computed: {
    sectorCheckList: function() {
      let resultList = this.listSector;
      resultList.forEach(data => {
        let checked = this.selectedSector.find((element, index) => {
          return element.sector.sectorId == data.sectorId;
        });
        data.checked = checked;
      });
      return resultList;
    }
  },
  components: {
    "airline-dialog": AirlineDialog
  },
  watch: {
    "airline.airlineId": {
      immediate: true,
      handler(value) {
        this.flight.airline.airlineId = value;
        if (value != 0) {
          this.loadAirline(value);
          this.loadFlightByAirlineId(value);
          this.loadSectorByAirlineId(value);
        }
      }
    },
    listAirline: function(value = []) {
      const findSelected = item => {
        return item.airlineId == this.airline.airlineId;
      };

      this.selected = value.findIndex(findSelected);
    }
  }
};
</script>

<style>
.listScroll {
  max-height: 78vh !important;
  overflow-y: scroll;
}
.sectorScroll {
  max-height: 68vh !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
