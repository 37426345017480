import BasicService from "../service/BasicService";

class SectorService extends BasicService {
  getAllSector() {
    let url = `/sectors`;
    return this.axios.get(url).then(response => response.data);
  }
  getSectorByAirlineId(airlineId) {
    let url = `/airlines/${airlineId}/sectors`;
    return this.axios.get(url).then(response => response.data);
  }
  saveOrDeleteSectorAndAirline(airlineId, sectorId, airlineSectorId) {
    let url = `/airlines/${airlineId}/sectors/${sectorId}/sectorAndAirline/${airlineSectorId}/`;
    return this.axios.post(url).then(response => response.data);
  }
  getAllSectorByAirlineId(airlineId) {
    let url = `/airlines/${airlineId}/allSector`;
    return this.axios.get(url).then(response => response.data);
  }
}

const sectorService = new SectorService();
export default sectorService;
