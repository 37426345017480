import BasicService from "../service/BasicService";

class FlightService extends BasicService {
  constructor() {
    super();
  }
  /**
   *
   * @param {Number} airlineId
   *
   */
  getFlightByAirlineId(airlineId) {
    let url = `/airlines/${airlineId}/flights`;
    return this.axios.get(url).then(response => response.data);
  }

  updateFlight(flightId, flight) {
    let url = `/flights/${flightId}`;
    return this.axios.put(url, flight).then(response => response.data);
  }

  deleteFlight(flightId) {
    let url = `/flights/${flightId}`;
    return this.axios.delete(url).then(response => response.data);
  }

  addFlight(flight) {
    let url = `/flights`;
    return this.axios.post(url, flight).then(response => response.data);
  }
}
const flightService = new FlightService();
export default flightService;
