import BasicService from "../service/BasicService";

class AirlineService extends BasicService {
  constructor() {
    super();
  }

  getAllAirline() {
    let url = `/airlines`;
    return this.axios.get(url).then(response => response.data);
  }

  /**
   *
   * @param {Number} airlineId
   */
  getAirlineById(airlineId) {
    let url = `/airlines/${airlineId}`;
    return this.axios.get(url).then(response => response.data);
  }

  updateAirline(airlineId, airline) {
    let url = `/airlines/${airlineId}`;
    return this.axios.put(url, airline).then(response => response.data);
  }

  /**
   *
   * @param {Airline} airline
   */
  addAirline(airline) {
    let url = `/airlines`;
    return this.axios.post(url, airline).then(response => response.data);
  }

  /**
   *
   * @param {Number} airlineId
   */
  deleteAirline(airlineId) {
    let url = `/airlines/${airlineId}`;
    return this.axios.delete(url).then(response => response.data);
  }
}
const airlineService = new AirlineService();
export default airlineService;
